import React, {useState} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

import frontendEnvironment from '../frontend_environment'
import SampleCodeAndImage from './SampleCodeAndImage'

function About() {

  const [bytecode, setBytecode] = useState(null)

  const sampleCode = 
`Repeat X 0 100 {
  Pen X\n\
  Line X 0 X 100\n\
}`
  
  return (
    <Container>
      <Row className="pt-2 dbn-about-body" >
        <Col sm={12} md={9} lg={8} xl={7}>
          <div className="p-3 dbn-about-content">
            <h2>Design By Numbers NFT</h2>
            <h6><em>Create on-chain NFTs by deploying smart contracts that render images</em></h6>

            <p>
              Design By Numbers
                (<a target="_blank" rel="noreferrer" href="https://dbn.media.mit.edu/whatisdbn.html">DBN</a>)
              is a programming language and environment
              developed by John Maeda in 1999 to help teach ideas about computation
              to designers and artists. It's fully documented in
              a <a target="_blank" rel="noreferrer" href="https://mitpress.mit.edu/books/design-numbers">book</a> by the same name.
            </p>

            <p>
              The language allows people to draw in 101 levels of gray to a 101✕101 bitmap.
              There is basic support for variables, loops, conditionals, and user-defined-procedures.
            </p>

            <SampleCodeAndImage
              code={sampleCode}
              onBytecodePresent={setBytecode}
            />

            <h4>NFT</h4>

            <p>
              This tool enables you to create NFTs by compiling DBN code directly to a smart contract that
              can render itself as a bitmap. The art of the NFT is the <em>code itself</em> stored on the blockchain.
            </p>

            <h6>The above code is stored as:</h6>
            <pre className="dbn-low-level-code wordwrap" style={{height: "100px"}}>
              {bytecode}
            </pre>

            <p>
              Minting a DBNFT involves deploying your drawing as a smart contract
              to the Ethereum blockchain. When it's time to get the image for the NFT,
              that smart contract is called and a bitmap image is created and returned.
            </p>


            <h4>Minting</h4>

            <p>
              The first 101 DBNFTs will be minted based off an allowlist. After that,
              minting will be open to anyone! There will be a Ξ0.01 fee, all of which
              will be donated to the <a href="https://processing.org/">Processing org</a>.
            </p>

            <p>
              Get started by reading the <Link to="/reference">reference</Link>,
              starting to <Link to="/create">create</Link>, or joining
              the <a href={frontendEnvironment.config.discordInvite}>Discord</a> to
              ask questions, share drawings, or stay up to date. You can
              also examine
              the <a
                href={
                  frontendEnvironment.config.etherscanBase +
                  "/address/" +
                  frontendEnvironment.config.coordinatorContractAddress +
                  "#code"
                }
              >
                token contract on Etherscan.
              </a>
            </p>

          </div>
        </Col>

        <Col sm={12} md={12} lg={4} xl={5}>
          <Row className="p-3">
            <div className="mt-3 p-3 dbn-about-get-started text-white">
                <h4>Start Creating:</h4>
                
                  <ul>
                    <li>
                      <Link className="text-white" to="/create">Editor</Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/reference">Language Reference</Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/gallery">Gallery</Link>
                    </li>
                  </ul>

            </div>
          </Row>
          <Row className="p-3">
            <div style={{backgroundColor: "blue"}} >
            </div>
          </Row>

        </Col>
      </Row>
    </Container>
  )
}

export default About